export const environment = {
  production: true,
  envName: 'qa',
  apiUrl: 'https://api-itracing-qa.comsatelsmart.com/backs/smart-tracing',
  notificacionUrl: 'wss://soa-qa.comsatel.com.pe/notificaciones-utility/canales',
  chatWebUrl: 'https://api-itracing-qa.comsatelsmart.com/backs/chat-interactivo',
  clienteUrl: 'http://itracing-qa.comsatelsmart.com/#/entrega',
  apiKeyGoogleMaps: 'AIzaSyBkaKnB3OhZiJ2YCMH5jOkEKvbchwFxh24',
  apiKeyGoogleRecaptcha: '6LfWKPwUAAAAADDXJRFlQA-RaPVIBBRfi4v5P_D9',
  mapInitialPosition: { lat: -12.09918608864384, lng: -77.02581502497196 },
  webUrl: 'https://www.comsatel.com.pe/nosotros/acerca-de-comsatel/',
  logoConTexto: 'assets/images/comsatellogo.svg',
  apiKeyHereMaps: 'wrJj3cNnDMTiphnw56uyOHV03ihkGlxnQEBst7PfDbo',
  terminosLegales: 'https://www.smart-tracing.com/terminos-y-condiciones/',
  politicasPrivacidad: 'https://www.smart-tracing.com/politica-de-privacidad/'
};
